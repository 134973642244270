import React from 'react';

import { Layout } from 'components/Layout';
import { StartHero } from 'components/sections/StartHero';
import { Faq, Topic } from 'components/sections/Faq';
import { Magazine, MagazineArticleCard } from 'components/sections/Magazine';
import { Reviews } from 'components/sections/Reviews';
import { reviews } from 'content/reviews';
import { Gutter } from 'components/Gutter';
import { HowToCards } from 'components/sections/HowToCards';
import { howToCardsLoan } from 'content/howToCards';
import { PartnerSlider } from 'components/PartnerSlider';

import styles from './Start.module.scss';

interface Props {
  articles: MagazineArticleCard[];
  faq: {
    title: string;
    questions: Topic[];
  };
}

export const Start: React.FC<Props> = ({ articles, faq }) => (
  <Layout>
    <StartHero />

    <Gutter size="negative">
      <div className={styles.partnersWrapper}>
        <span>Tack vare våra partners</span>
        <PartnerSlider />
      </div>
    </Gutter>

    <Gutter size="small">
      <HowToCards
        title="Så fungerar blipp:pay"
        howToItems={howToCardsLoan.howToItems}
        hideImages
        showBg
        showIcons
      />
    </Gutter>
    <Gutter size="large">
      <Reviews title={reviews.title} reviews={reviews.reviews} />
    </Gutter>

    <Gutter size="large">
      <Magazine articles={articles} />
    </Gutter>
    <Gutter size="large">
      <Faq title={faq.title} questions={faq.questions} />
    </Gutter>
  </Layout>
);
